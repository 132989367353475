<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Activity Log Detail</h1>
      </div>
      <div class="title-panel mt-3">Activity Detail</div>
      <div class="no-gutters bg-white">
        <div class="p-3">
          <b-row>
            <b-col md="12"><p class="mb-1 p-font">Date :</p>
              <p v-if="detail.created_time_string">{{ detail.created_time_string }}</p>
              <p  v-else>-</p>
            </b-col>
            <!-- <b-col md="12"><p class="mb-1 p-font">Email :</p>
              <p v-if="detail.display_name">{{ detail.display_name }}</p>
              <p  v-else>-</p>
            </b-col> -->
            <b-col md="12"><p class="mb-1 p-font">Name - Surname :</p>
              <p v-if="detail.display_name">{{ detail.display_name }}</p>
              <p  v-else>-</p>
            </b-col>
            <b-col md="12"><p class="mb-1 p-font">IP Address :</p>
              <p v-if="detail.ip_address">{{ detail.ip_address }}</p>
              <p  v-else>-</p>
            </b-col>
            <b-col md="12"><p class="mb-1 p-font">Activity :</p>
              <p class="text-wrap-show" v-if="detail.activity">{{ detail.activity }}</p>
              <p  v-else>-</p>
            </b-col>
            <b-col md="12"><p class="mb-1 p-font">Target :</p>
              <p class="text-wrap-show" v-if="detail.target">{{ detail.target }}</p>
              <p  v-else>-</p>
            </b-col>
            <b-col md="12"><p class="mb-1 p-font">Description :</p>
              <p class="text-wrap-show" v-if="detail.detail">{{ detail.detail }}</p>
              <p  v-else>-</p>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    OtherLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {},
      isLoading: true,
    };
  },
  async created() {
    await this.getDetailById();
  },
  methods: {
    async getDetailById() {
      this.isLoading = true
      await this.$store.dispatch("getDetailById", this.id);
      const data = this.$store.state.activitylog.stateDetailById;
      if (data.result === 1) {
        this.detail = data.detail;
        this.isLoading = false;
      } else {
        this.isLoading = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-wrap-show {
  word-wrap: break-word;
}
.p-font {
  color: #212529;
  font-size: 14px;
  font-weight: bold;
}
.space-div {
  div:nth-child(even) {
    margin-bottom: 1px;
    margin-left: 1rem;
  }
  div {
    padding: 0.25rem;
  }
}
</style>
